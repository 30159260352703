<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Gestione Solleciti</h4>
    </div>

    <div class="col-12">
      <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
      <b-modal ref="my-modal" hide-footer title="Eliminazione Template">
        <div class="d-block text-center">
          <h3>Sicuro di voler eliminare questo template?</h3>
        </div>
        <b-button class="mt-3" variant="outline-danger" style="backgroud-color: red;" block @click="hideModal">Chiudi</b-button>
        <b-button class="mt-2" variant="outline-warning" style="backgroud-color: green;" block @click="deleteSollecitoTemplate">Elimina</b-button>
      </b-modal>
      <card>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per pagina">
              <el-option
                class="select-default"
                v-for="item in perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <!-- <el-input type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="Cerca cliente"
                      v-model="searchQuery"
                      aria-controls="datatables"/> -->
          </div>
          <div class="col-sm-12" v-if="hasLoaded">
            <el-table stripe
                      style="width: 100%;"
                      :data="queriedData"
                      border>
              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth*2/3"
                               :prop="column.prop"
                               :label="column.label">
              </el-table-column>
              <el-table-column
                :min-width="120"
                fixed="right"
                label="Azioni">
                <template slot-scope="props">
                  <!-- <a v-tooltip.top-center="'Like'" class="btn-info btn-simple btn-link"
                     @click="handleLike(props.$index, props.row)">
                    <i class="fa fa-heart"></i></a> -->
                  <a v-tooltip.top-center="'Edit'" class="btn-warning btn-simple btn-link"
                     @click="handleEdit(props.$index, props.row)"><i
                    class="fa fa-edit"></i></a>
                  <a v-tooltip.top-center="'Delete'" class="btn-danger btn-simple btn-link"
                     @click="handleDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Da {{from + 1}} a {{to}} di {{total}} invii</p>
          </div>
          <l-pagination class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import {Pagination as LPagination} from 'src/components/index'
  import TemplateService from 'src/services/TemplateService'
  import Vue from 'vue'
  import { ModalPlugin } from 'bootstrap-vue'
  Vue.use(ModalPlugin)
  import { ButtonPlugin } from 'bootstrap-vue'
  Vue.use(ButtonPlugin)

  export default {
    components: {
      LPagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        let result = this.tableData
        if (this.searchQuery !== '') {
          result = this.fuseSearch.search(this.searchQuery)
          this.pagination.total = result.length
        }
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      perPageOptions: function () {
        let a = this.tableData.length / 5
        let b = this.tableData.length % 5
        if (a >= 10 || a >= 9 && b > 0) {
          this.pagination.perPageOptions = [5, 10, 25, 50]
          return [5, 10, 25, 50]
        } else if (a >= 5 || a >= 4 && b > 0) {
          this.pagination.perPageOptions = [5, 10, 25]
          return [5, 10, 25]
        } else if (a >= 2 || a >= 1 && b > 0) {
          this.pagination.perPageOptions = [5, 10]
          return [5, 10]
        } else {
          this.pagination.perPageOptions = [5]
          return [5]
        }
      }
    },
    data () {
      return {
        hasLoaded: false,
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['name', 'email', 'age'],
        tableColumns: [
          {
            prop: 'name',
            label: 'Nome',
            minWidth: 250
          },
          {
            prop: 'iteration',
            label: 'Cadenza',
            minWidth: 200
          },
          {
            prop: 'count',
            label: 'Numero',
            minWidth: 250
          },
          {
            prop: 'items',
            label: 'Elementi',
            minWidth: 250
          }
        ],
        tableData: [],
        fuseSearch: null,
        delete_template_id:null
      }
    },
    methods: {
      async fetchData(){
        try {
          const templates = await TemplateService.getTemplates()
          this.tableData = templates.data.map(el => {
            let obj = {}
            obj.id = el.id
            obj.name = el.name
            obj.iteration = el.settings.iteration === '1:1g' ? '1 al Giorno' : '1 ogni ' + el.settings.iteration.split(":")[1].slice(0,1) + " Giorni"
            obj.count = el.settings.count_msg
            obj.items = el.items.length > 0 ? el.items.map(el => el.label).join(", ") : '-'
            return obj
          })
        } catch(err) {
          console.log('err', err)
        } finally {
          this.hasLoaded = true
        }
      },
      handleLike (index, row) {
        alert(`Your want to like ${row.first_name}`)
      },
      handleEdit (index, row) {
        this.$router.push({
          path: '/admin/edit-sollecito/'+row.id
        })
      },
      handleDelete (index, row) {
                
        this.delete_template_id = row.id
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.delete_template_id = null
        this.$refs['my-modal'].hide()
      },
      async deleteSollecitoTemplate(){     
        try {
          this.hasLoaded = false
          const ris = await TemplateService.deleteTemplate(this.delete_template_id)
          console.log('ris', ris)
          
          await this.fetchData()
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.$refs['my-modal'].hide()
          this.hasLoaded = true
        }      
      }
    },    
    async mounted () {
      this.fetchData()
    }
  }
</script>
<style>
</style>
